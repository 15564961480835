<template>
  <div>
    <div
      :style="{ width: this.$oucy.pageWidth + 'px', display: 'inline-block' }"
    >
      <el-row>
        <el-col :span="5" v-if="$oucy.isICPAudit">
          <div class="left_nav">
            <myMenu
              :list="allGlobalClassifyList1"
              :default-active="defaultActive"
              @menuSelect="menuSelect1"
            >
              <span
                class="bold_span m-b-20"
                style="
                  padding-top: 30px;
                  padding-left: 20px;
                  display: inline-block;
                "
                :class="{ selsct: type == 1 }"
              >
                企业名片
              </span>
            </myMenu>
            <myMenu
              :list="allGlobalClassifyList0"
              :default-active="defaultActive"
              @menuSelect="menuSelect"
            >
              <span
                class="bold_span m-b-20"
                style="
                  padding-top: 30px;
                  padding-left: 20px;
                  display: inline-block;
                "
                :class="{ selsct: type == 0 }"
              >
                个人名片
              </span>
            </myMenu>
          </div>
        </el-col>
        <el-col :span="$oucy.isICPAudit ? 19 : 24">
          <div class="top_sort">
            <div class="myauth" v-if="$oucy.isICPAudit">
              <div class="myauthLift">位置</div>
              <div class="text-left">
                <button
                  class="mybutton"
                  :class="selectedChina.chinaId == null ? 'selectedChina' : ''"
                  @click="setChinaId(null, 0)"
                >
                  全部
                </button>
                <button
                  class="mybutton"
                  :class="
                    selectedChina.chinaId == v.chinaId ? 'selectedChina' : ''
                  "
                  v-for="(v, i) of chinaList"
                  :key="i"
                  @click="setChinaId(v, 0)"
                >
                  {{ v.chinaName }}
                </button>
              </div>
            </div>
            <!-- 二层 -->
            <div class="myauth m-t-10" v-if="chinaList1 && chinaList1.length">
              <div class="myauthLift"></div>
              <div class="text-left">
                <button
                  class="mybutton"
                  :class="selectedChina1.chinaId == null ? 'selectedChina' : ''"
                  @click="setChinaId(null, 1)"
                >
                  全部
                </button>
                <button
                  class="mybutton"
                  :class="
                    selectedChina1.chinaId == v.chinaId ? 'selectedChina' : ''
                  "
                  v-for="(v, i) of chinaList1"
                  :key="i"
                  @click="setChinaId(v, 1)"
                >
                  {{ v.chinaName }}
                </button>
              </div>
            </div>
            <!-- 三层 -->
            <div class="myauth m-t-10" v-if="chinaList2 && chinaList2.length">
              <div class="myauthLift"></div>
              <div class="text-left">
                <button
                  class="mybutton"
                  :class="selectedChina2.chinaId == null ? 'selectedChina' : ''"
                  @click="setChinaId(null, 2)"
                >
                  全部
                </button>
                <button
                  class="mybutton"
                  :class="
                    selectedChina2.chinaId == v.chinaId ? 'selectedChina' : ''
                  "
                  v-for="(v, i) of chinaList2"
                  :key="i"
                  @click="setChinaId(v, 2)"
                >
                  {{ v.chinaName }}
                </button>
              </div>
            </div>
            <!-- 四层 -->
            <div class="myauth m-t-10" v-if="chinaList3 && chinaList3.length">
              <div class="myauthLift"></div>
              <div class="text-left">
                <button
                  class="mybutton"
                  :class="selectedChina3.chinaId == null ? 'selectedChina' : ''"
                  @click="setChinaId(null, 3)"
                >
                  全部
                </button>
                <button
                  class="mybutton"
                  :class="
                    selectedChina3.chinaId == v.chinaId ? 'selectedChina' : ''
                  "
                  v-for="(v, i) of chinaList3"
                  :key="i"
                  @click="setChinaId(v, 3)"
                >
                  {{ v.chinaName }}
                </button>
              </div>
            </div>
            <!-- 五层 -->
            <div class="myauth m-t-10" v-if="chinaList4 && chinaList4.length">
              <div class="myauthLift"></div>
              <div class="text-left">
                <button
                  class="mybutton"
                  :class="selectedChina4.chinaId == null ? 'selectedChina' : ''"
                  @click="setChinaId(null, 4)"
                >
                  全部
                </button>
                <button
                  class="mybutton"
                  :class="
                    selectedChina4.chinaId == v.chinaId ? 'selectedChina' : ''
                  "
                  v-for="(v, i) of chinaList4"
                  :key="i"
                  @click="setChinaId(v, 2)"
                >
                  {{ v.chinaName }}
                </button>
              </div>
            </div>
            <div class="sort">
              <span>排序</span>
              <el-radio-group
                v-model="QueryFurnitureLibrary.cardSortEnum"
                size="small"
                fill="#2090FF"
                @change="getCardPersonList"
              >
                <el-radio-button :label="0">全部</el-radio-button>
                <el-radio-button :label="1">最新</el-radio-button>
                <el-radio-button :label="2">距离</el-radio-button>
                <el-radio-button :label="3">收藏</el-radio-button>
                <el-radio-button :label="4">人气</el-radio-button>
                <el-radio-button :label="5">点赞量</el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div class="middle">
            <NoData
              v-if="!ReturnsObject.content || !ReturnsObject.content.length"
              style="
                background-color: #fff;
                margin-top: 10px;
                margin-left: 10px;
              "
            ></NoData>
            <div class="enterprise_card" v-if="type == 1">
              <ul class="text-left">
                <li
                  v-for="(item, index) in ReturnsObject.content"
                  :key="index"
                  @click="$oucy.go('/enterpriseDetail?id=' + item.id)"
                >
                  <el-row>
                    <el-col :span="7">
                      <img
                        class="com_head"
                        :src="$oucy.ossUrl + item.enterpriseAvata"
                        v-if="item.enterpriseAvata"
                      />
                    </el-col>
                    <el-col :span="15">
                      <span>{{ item.enterpriseName }}</span>
                    </el-col>
                  </el-row>
                  <el-row style="margin-left: -5px">
                    <el-col :span="8">
                      <div class="info">
                        <p>法定代表人</p>
                        <p v-if="item.enterpriseLegalPerson">
                          {{ item.enterpriseLegalPerson }}
                        </p>
                        <p v-else>未填写</p>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="info">
                        <p>员工数量</p>
                        <p>
                          {{
                            (item.cardEmployeeFullList &&
                              item.cardEmployeeFullList.length) ||
                            0
                          }}
                        </p>
                      </div>
                    </el-col>
                    <el-col
                      :span="8"
                      v-if="
                        item.china &&
                        item.china.namePath &&
                        $oucy.strToArray(item.china.namePath, '/') &&
                        $oucy.strToArray(item.china.namePath, '/').length > 0
                      "
                    >
                      <div class="info">
                        <p
                          class="line-1"
                          :title="$oucy.strToArray(item.china.namePath, '/')[0]"
                          v-if="
                            item.china &&
                            item.china.namePath &&
                            $oucy.strToArray(item.china.namePath, '/') &&
                            $oucy.strToArray(item.china.namePath, '/').length >
                              0
                          "
                        >
                          {{ $oucy.strToArray(item.china.namePath, "/")[0] }}
                        </p>
                        <!-- <p v-else>无</p> -->
                        <p
                          class="line-1"
                          :title="$oucy.strToArray(item.china.namePath, '/')[1]"
                          v-if="
                            item.china &&
                            item.china.namePath &&
                            $oucy.strToArray(item.china.namePath, '/') &&
                            $oucy.strToArray(item.china.namePath, '/').length >
                              1
                          "
                        >
                          {{ $oucy.strToArray(item.china.namePath, "/")[1] }}
                        </p>
                        <!-- <p v-else>无</p> -->
                      </div>
                    </el-col>
                  </el-row>
                  <el-row
                    style="
                      margin-top: 15px;
                      margin-left: -5px;
                      margin-right: -5px;
                    "
                    v-if="item.cardEmployeeFullList"
                  >
                    <el-col
                      :span="8"
                      v-for="(v, i) of item.cardEmployeeFullList"
                      v-if="i < 3"
                      :key="i"
                    >
                      <div
                        style="
                          width: 100%;
                          padding: 5px;
                          box-sizing: border-box;
                        "
                      >
                        <img
                          :src="$oucy.ossUrl + v.employeePic"
                          style="width: 100%; height: 80px"
                          @click.stop="
                            $oucy.go(
                              '/staffDetail?id=' +
                                v.id +
                                '&enterpriseId=' +
                                item.id
                            )
                          "
                          v-if="v.employeePic"
                        />
                      </div>
                      <div
                        class="staff_info"
                        @click.stop="
                          $oucy.go(
                            '/staffDetail?id=' +
                              v.id +
                              '&enterpriseId=' +
                              item.id
                          )
                        "
                      >
                        <p>{{ v.employeeName }}</p>
                        <p class="staff_info_zw">{{ v.employeeJob }}</p>
                      </div>
                    </el-col>
                  </el-row>
                  <div v-else style="height: 126px; margin-top: 15px"></div>
                </li>
              </ul>
            </div>
            <div class="personal_card" v-if="type == 0">
              <ul class="text-left">
                <li
                  v-for="(item, index) in ReturnsObject.content"
                  :key="index"
                  @click="$oucy.go('/personageDetail?id=' + item.id)"
                >
                  <el-row>
                    <el-col :span="8">
                      <img
                        class="com_pic"
                        :src="$oucy.ossUrl + item.cardPic"
                        v-if="item.cardPic"
                      />
                    </el-col>
                    <el-col :span="16" class="text-left p-l-10">
                      <div
                        style="
                          font-size: 16px;
                          font-family: Microsoft YaHei;
                          font-weight: bold;
                          line-height: 24px;
                        "
                      >
                        {{ item.cardName }}
                      </div>
                      <div
                        class="line-1"
                        style="
                          font-size: 12px;
                          font-family: Microsoft YaHei;
                          font-weight: 400;
                          color: #888888;
                          line-height: 16px;
                        "
                      >
                        {{ item.cardJob }}
                      </div>
                      <div
                        class="line-1"
                        style="
                          font-size: 12px;
                          font-family: Microsoft YaHei;
                          font-weight: 400;
                          color: #888888;
                          line-height: 16px;
                        "
                      >
                        {{ item.cardSelf }}
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <div>
                        <span
                          style="
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #111111;
                          "
                        >
                          联系方式
                        </span>
                      </div>
                    </el-col>
                    <el-col :span="16">
                      <div>
                        <span
                          style="
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #aaaaaa;
                          "
                        >
                          （申请合作查看完整号码）
                        </span>
                      </div>
                    </el-col>
                  </el-row>
                  <div class="contact">
                    <span>{{ item.cardPhone }}</span>
                    <el-button
                      type="text"
                      size="mini"
                      @click.stop="openImMessage(item)"
                    >
                      申请合作
                    </el-button>
                  </div>
                  <el-row>
                    <el-col :span="6">
                      <div class="info">
                        <p>{{ item.cardPopularity }}</p>
                        <p>人气</p>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="info">
                        <p>{{ item.cardCollect }}</p>
                        <p>收藏</p>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="info">
                        <p>{{ item.cardTranspond }}</p>
                        <p>转发</p>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="info">
                        <p>{{ item.cardLike }}</p>
                        <p>点赞</p>
                      </div>
                    </el-col>
                  </el-row>
                </li>
              </ul>
            </div>
          </div>
          <div class="pagination">
            <el-pagination
              v-if="ReturnsObject.content"
              background
              layout="sizes, prev, pager, next, jumper, ->, total, slot"
              :page-size="ReturnsObject.size"
              :total="ReturnsObject.totalElements"
              @size-change="sizeChange"
              @current-change="currentChange"
              @prev-click="currentChange"
              :page-sizes="pageSizes"
              @next-click="currentChange"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import {
    enterpriseinfo,
    ltIdentity,
    china,
    cardCategory,
    cardPerson,
    cardEnterprise,
  } from "@/service/index.js";
  import { localSet, localGet, localDel } from "@/store/store";
  import oucy from "@/util/oucyUtil";
  import NoData from "../../../components/NoData.vue";

  export default {
    name: "Card",
    data() {
      return {
        type: 1,
        bannerHeight: 1000,
        allGlobalClassifyList1: [],
        allGlobalClassifyList0: [],
        // 请求对象
        QueryFurnitureLibrary: {
          limit: 15,
          start: 0,
          cardStatus: 1,
          // enterpriseAuthenticationLevel: 0, //   认证级别：0全部 1实名认证 2信守约 3品质工厂
          cardSortEnum: 0, //  排序方式：0 销量[默认], 排序1 最新, 3 收藏, 4 人气,
          cardRegion: null, //    位置区域
          siteId: null, //    站点
          cardCategory: null, //名片分类
          identityId: null, //名片分类
        },
        // 返回对象
        ReturnsObject: {},

        defaultActive: "全部企业",

        // 位置
        chinaList: [],
        selectedChina: {},
        chinaList1: [],
        selectedChina1: {},
        chinaList2: [],
        selectedChina2: {},
        chinaList3: [],
        selectedChina3: {},
        chinaList4: [],
        selectedChina4: {},
        pageSizes: [15, 30, 90, 120],
      };
    },
    components: { NoData },
    mounted() {
      if (this.$route.query.type && this.$route.query.type != "null") {
        this.type = this.$route.query.type;
      }
      if (
        this.$route.query.cardCategory &&
        this.$route.query.cardCategory != "null"
      ) {
        if (!this.$route.query.cardCategory.includes("全部")) {
          this.QueryFurnitureLibrary.cardCategory =
            this.$route.query.cardCategory || null;
          this.QueryFurnitureLibrary.identityId =
            this.$route.query.cardCategory || null;
        }
        this.defaultActive = this.$route.query.cardCategory;
      } else {
        if (this.type == 0) {
          this.defaultActive = "全部个人";
        } else {
          this.defaultActive = "全部企业";
        }
      }
      this.getCardCategoryList();
      this.getCardPersonList();
      this.findChina();
    },
    methods: {
      // 请求分类列表
      getCardCategoryList() {
        cardCategory.findAllLtIdentity().then((res) => {
          this.allGlobalClassifyList1 = res || [];
          this.allGlobalClassifyList1.unshift({
            identityName: "企业名片",
            id: "全部企业",
            categoryType: 1,
            identityIconPc: "1c2d07f59a6545649be457986be9d3c6.png",
          });
          this.$oucy.keyAs(this.allGlobalClassifyList1, "identityName");
        });
        cardCategory.getCardCategoryList({ categoryType: 0 }).then((res) => {
          this.allGlobalClassifyList0 = res || [];
          this.allGlobalClassifyList0.unshift({
            categoryName: "个人名片",
            id: "全部个人",
            categoryType: 0,
            categoryIconPc: "d500496c35204412a4b5668f485f63b1.png",
          });
          this.$oucy.keyAs(this.allGlobalClassifyList0, "categoryName");
        });
      },
      // 切换菜单回调
      menuSelect1(v) {
        this.type = 1;
        console.log(v);
        this.defaultActive = v;
        this.QueryFurnitureLibrary.cardCategory = v != "全部企业" ? v : null;
        this.QueryFurnitureLibrary.identityId = v != "全部企业" ? v : null;
        this.getCardPersonList();
        if (this.QueryFurnitureLibrary.cardCategory) {
          this.$oucy.replace(
            "/card?type=1&cardCategory=" +
              this.QueryFurnitureLibrary.cardCategory
          );
        } else {
          this.$oucy.replace("/card?type=1");
        }
      },
      menuSelect(v) {
        this.type = 0;
        console.log(v);
        this.defaultActive = v;
        this.QueryFurnitureLibrary.cardCategory = v != "全部个人" ? v : null;
        this.QueryFurnitureLibrary.identityId = v != "全部个人" ? v : null;
        this.getCardPersonList();
        if (this.QueryFurnitureLibrary.cardCategory) {
          this.$oucy.replace(
            "/card?type=0&cardCategory=" +
              this.QueryFurnitureLibrary.cardCategory
          );
        } else {
          this.$oucy.replace("/card?type=0");
        }
      },
      // 查询企业列表
      getCardPersonList() {
        this.QueryFurnitureLibrary.cardSort =
          this.QueryFurnitureLibrary.cardSortEnum;
        this.QueryFurnitureLibrary.regionId =
          this.QueryFurnitureLibrary.cardRegion;

        if (this.type == 0) {
          cardPerson
            .getCardPersonList(this.QueryFurnitureLibrary)
            .then((res) => {
              this.ReturnsObject = res || {};
            });
        } else {
          cardEnterprise
            .getCardEnterpriseList(this.QueryFurnitureLibrary)
            .then((res) => {
              this.ReturnsObject = res || {};
            });
        }
      },

      // 切换页数
      sizeChange: function (pageSize) {
        const me = this;
        me.QueryFurnitureLibrary.limit = pageSize;
        me.getCardPersonList();
      },
      // 切换页码
      currentChange: function (current) {
        const me = this;
        me.QueryFurnitureLibrary.start = current - 1;
        me.getCardPersonList();
      },
      findChina() {
        china.findChinaTree({ chinaId: null }).then((res) => {
          this.chinaList = res.children;
          /*                chinaList=Array.from(res).map(item => ({
                                    value: item.chinaId,
                                    label: item.chinaName,
                                    leaf: !item.hasChildren
                                }));*/
        });
      },
      // 点击设置 位置
      setChinaId(v, i) {
        if (i == 0) {
          if (v) {
            this.selectedChina = v;
            if (v.children) {
              this.chinaList1 = v.children;
            } else {
              this.chinaList1 = [];
            }
          } else {
            this.selectedChina = {};
            this.chinaList1 = [];
          }
          this.selectedChina1 = {};
          this.chinaList2 = [];
          this.selectedChina2 = {};
          this.chinaList3 = [];
          this.selectedChina3 = {};
          this.chinaList4 = [];
          this.selectedChina4 = {};
        } else if (i == 1) {
          if (v) {
            this.selectedChina1 = v;
            if (v.children) {
              this.chinaList2 = v.children;
            } else {
              this.chinaList2 = [];
            }
          } else {
            this.selectedChina1 = {};
            this.chinaList2 = [];
          }
          this.selectedChina2 = {};
          this.chinaList3 = [];
          this.selectedChina3 = {};
          this.chinaList4 = [];
          this.selectedChina4 = {};
        } else if (i == 2) {
          if (v) {
            this.selectedChina2 = v;
            if (v.children) {
              this.chinaList3 = v.children;
            } else {
              this.chinaList3 = [];
            }
          } else {
            this.selectedChina2 = {};
          }
          this.chinaList3 = [];
          this.selectedChina3 = {};
          this.chinaList4 = [];
          this.selectedChina4 = {};
        } else if (i == 3) {
          if (v) {
            this.selectedChina3 = v;
            if (v.children) {
              this.chinaList4 = v.children;
            } else {
              this.chinaList4 = [];
            }
          } else {
            this.selectedChina3 = {};
          }
          this.chinaList4 = [];
          this.selectedChina4 = {};
        } else if (i == 4) {
          if (v) {
            this.selectedChina4 = v;
          } else {
            this.selectedChina4 = {};
          }
        }

        if (v) {
          this.QueryFurnitureLibrary.cardRegion = v.chinaId;
        } else {
          if (this.selectedChina3.chinaId) {
            this.QueryFurnitureLibrary.cardRegion = this.selectedChina3.chinaId;
          } else if (this.selectedChina2.chinaId) {
            this.QueryFurnitureLibrary.cardRegion = this.selectedChina2.chinaId;
          } else if (this.selectedChina1.chinaId) {
            this.QueryFurnitureLibrary.cardRegion = this.selectedChina1.chinaId;
          } else if (this.selectedChina.chinaId) {
            this.QueryFurnitureLibrary.cardRegion = this.selectedChina.chinaId;
          } else {
            this.QueryFurnitureLibrary.cardRegion = null;
          }
        }
        this.getCardPersonList();
      },
      openImMessage(v) {
        console.log(v);
        this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE, {
          targetUserId: v.userDetailFull.id,
        });
        // this.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE,v);
      },
    },
  };
</script>
<style scoped>
  .left_nav {
    margin-top: 20px;
  }

  .left_nav .el-menu {
    /*margin-left:20px;*/
    z-index: 1000;
  }

  .left_nav .el-button--text {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    margin: 30px 0 0 30px;
    color: #111111;
  }

  .left_nav .el-button--text:focus {
    color: #2090ff;
  }

  .el-menu {
    border-right: 0 !important;
    height: 900px;
  }

  .el-menu-item {
    font-size: 13px;
  }

  .nav img {
    margin-right: 8px;
  }

  .nav:hover .icon1 {
    display: none;
  }

  .nav:hover .icon2 {
    display: inline-block;
  }

  .icon2 {
    display: none;
  }

  .el-collapse {
    border: none;
  }

  .el-collapse-item__header {
    border: none;
  }

  .el-collapse-item__wrap {
    margin-top: 20px;
  }

  .top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
  }

  .place .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
    margin-bottom: 10px;
  }

  .place {
    margin-bottom: 20px;
    margin-left: -170px;
  }

  .place > span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
  }

  .auth {
    margin-bottom: 30px;
    display: flex;
  }

  .auth > span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
  }

  .auth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #f6f7fb;
    margin-left: 10px;
    margin-top: 10px;
    text-align: left;
  }

  .top_sort .el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
  }

  .sort /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #f6f7fb;
    margin-left: 10px;
  }

  .sort {
    margin-bottom: 30px;
    display: flex;
  }

  .sort > span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
  }

  .el-radio-button--text:hover {
    color: rgb(32, 144, 255) !important;
  }

  .el-radio-button--mini {
    border: none;
  }

  .el-radio-button--mini:hover {
    background-color: rgb(32, 144, 255) !important;
  }

  .el-radio-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
  }

  /deep/ .el-collapse-item__wrap {
    margin-top: 20px;
    text-align: center;
  }

  .middle ul li:nth-child(3n) {
    margin-right: 0px;
  }

  .middle ul li {
    width: 273px;
    /*height: 250px;*/
    background-color: #ffffff;
    padding: 24px;
    display: inline-block;
    /*float: left;*/
    margin: 10px;
    list-style: none;
  }

  .personal_card ul li {
    /*height: 250px;*/
  }

  .enterprise_card {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /*margin-left: 10px;*/
  }

  .enterprise_card span {
    width: 240px;
    font-size: 16px;
    /*font-weight: bold;*/
    color: #111111;
    float: left;
    display: flex;
    padding: 10px 0 0 10px;
  }

  .com_head {
    width: 40px;
    height: 40px;
    border: 1px rgb(243, 243, 243) solid;
    float: left;
  }
  .com_pic {
    width: 60px;
    height: 60px;
    border: 1px rgb(243, 243, 243) solid;
    float: left;
  }
  .com_head img {
    margin: 15px auto;
    width: 100%;
    height: 100%;
  }
  .el-col-7 {
    width: 40px;
  }

  .enterprise_card .info {
    width: 80px;
    /*height: 43px;*/
    background: #f8f8f9;
    border-radius: 2px;
    /*margin-top: 30px;*/
    margin-left: 6px;
  }

  .enterprise_card .info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #aaaaaa;
    line-height: 22px;
    /*margin-top: -20px;*/
    text-align: center;
  }

  .enterprise_card .info p + p {
    color: #666666;
    /*padding-top: 10px;*/
  }

  .personal_card .info {
    width: 57px;

    background: #f8f8f9;
    border-radius: 2px;
  }

  .personal_card .info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
    line-height: 20px;
    text-align: center;
    /*margin-top: -20px;*/
  }

  .staff_info {
    width: 88px;
  }

  .staff_info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    text-align: center;
  }
  .staff_info p.staff_info_zw {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #aaaaaa;
    line-height: 20px;
    text-align: center;
  }

  .contact {
    width: 233px;
    /*height: 50px;*/
    background-color: rgb(243, 249, 255);
    margin: 6px auto;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .contact .el-button--text {
    width: 70px;
    border: 1px rgb(32, 144, 255) solid;
    float: right;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
  }

  .pagination {
    margin: 30px auto 120px;
  }

  /*/deep/ .btn-next {
    width: 128px;
    height: 46px !important;
    background: #FFFFFF !important;
}
*/
  /*/deep/ .btn-prev {
    width: 128px;
    height: 46px !important;
    background: #FFFFFF !important;
}
*/
  /*/deep/ .el-pager {
    position: relative;
    top: 9px;
}
*/
  /*/deep/ .number {
    background: #FFFFFF !important;
}

/deep/ .active {
    background: #2090ff !important;
}
*/
  .bus_recomm {
    width: 100%;
  }

  .left_nav {
    margin-top: 20px;
  }

  .left_nav .el-menu {
    /*margin-left:20px;*/
    z-index: 1000;
  }

  .el-menu {
    border-right: 0 !important;
    height: 900px;
  }

  .el-menu-item {
    font-size: 13px;
  }

  .nav img {
    margin-right: 8px;
  }

  .nav:hover .icon1 {
    display: none;
  }

  .nav:hover .icon2 {
    display: inline-block;
  }

  .icon2 {
    display: none;
  }

  .bold_span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    /*margin: 30px 0 0 30px;*/
  }

  .top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
  }

  .place .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
    margin-bottom: 10px;
  }

  .place {
    text-align: left;
    margin-bottom: 20px;
    /*margin-left: -170px;*/
    padding-left: 10px;
  }

  .place > span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
  }

  .auth {
    margin-bottom: 30px;
    display: flex;
  }

  .auth > span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
  }

  .auth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #f6f7fb;
    margin-left: 10px;
    /*margin-top: 10px;*/
    text-align: left;
  }

  .el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
  }

  .sort /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #f6f7fb;
    margin-left: 10px;
  }

  .sort {
    margin-bottom: 30px;
    display: flex;
  }

  .sort > span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
  }

  .el-radio-button--text:hover {
    color: rgb(32, 144, 255) !important;
  }

  .el-radio-button--mini {
    border: none;
  }

  .el-radio-button--mini:hover {
    background-color: rgb(32, 144, 255) !important;
  }

  .el-radio-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
  }

  /deep/ .el-collapse-item__wrap {
    margin-top: 20px;
    text-align: center;
  }

  /*.com_head {*/
  /*    width: 80px;*/
  /*    height: 80px;*/
  /*    border: 1px rgb(243, 243, 243) solid;*/
  /*    float: left;*/
  /*}*/

  /*.com_head img {*/
  /*    width: 73px;*/
  /*    !*height: 47px;*!*/
  /*    margin-top: 15px;*/
  /*}*/

  .middle_card_star {
    float: left;
    display: flex;
    margin-left: 10px;
  }

  .info {
    width: 70px;
    /*height: 43px;*/
    background-color: rgb(248, 248, 249);
    padding: 2px;
    margin: 15px auto 0px;
  }

  .info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 6px;
  }

  .goods_info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    text-align: left;
    margin-left: 7px;
  }

  .pagination {
    margin-top: 50px;
    line-height: 150px;
  }

  .middle ul li:nth-child(3n) {
    margin-right: 0px;
  }

  .items {
    margin-left: 10px;
  }

  .item {
    padding: 20px;
    background: #fff;
    margin-top: 10px;
    display: flex;
  }

  .logo {
    width: 168px;
    height: 168px;
    background: #fdfdfd;
    border: 1px solid #f3f3f3;
    border-radius: 1px;
  }

  .dot {
    padding-left: 30px;
    flex: 1;
  }

  .itemTop {
    align-items: flex-start;
  }

  .icon3 {
  }

  .icon4 {
    margin-right: 5px;
  }

  .cover {
    width: 80px;
    margin-left: 10px;
  }

  .myauth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #f6f7fb;
    /*margin-left: 10px;*/
    /*margin-top: 10px;*/
    text-align: left;
  }

  .mybutton {
    margin: 0 10px 10px 0;
    background: #f6f7fb;
    border: none;
    color: #606266;
    padding: 6px 12px;
    border-radius: 4px;
  }

  .text-left {
    text-align: left;
  }

  .myauth {
    display: flex;
    text-align: left;
  }

  .myauth .myauthLift {
    text-align: center;
    font-weight: bold;
    display: block;
    width: 50px;
    margin-right: 40px;
  }

  .myauth .text-left {
    flex: 1;
  }

  .selectedChina {
    color: #fff;
    background-color: rgb(32, 144, 255);
  }

  .el-radio-group {
    text-align: left;
  }
  .selsct {
    color: #409eff;
  }
</style>
